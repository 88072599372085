import React from 'react';
import classnames from 'classnames';
import {
  HubModule,
  HubModuleCTA,
  PropsWithTestId,
  TitleSizeType,
  UploadedImage,
} from '../../../../../../types';
import { HubModuleTypes, HubVisualStyles } from '../../../../../../constants';
import { useHubContext } from '../../../context/hubContext';
import { useRootSelector } from '../../../../../../store/hooks';
import { selectSiteType } from '../../../../../../store/features/siteType';
import { CallToAction } from '../../../CallToActionGroup';
import { HubModuleWrapper } from '../../HubModuleWrapper';
import { Paragraph, Heading, ModuleHeader } from '../../components';
import OneColumnImageRightColumnStyle from './OneColumnImageRightColumnStyle';
import { Image } from './Image';
import { Ripple } from '../../../Ripple';
import styles from './ColumnTextModule.module.scss';

export enum ColumnTextModuleColumnStyle {
  TWO_COLUMNS = 'TWO_COLUMNS',
  THREE_COLUMNS = 'THREE_COLUMNS',
  FOUR_COLUMNS = 'FOUR_COLUMNS',
  ONE_COLUMN_IMAGE_RIGHT = 'ONE_COLUMN_IMAGE_RIGHT',
}

export enum HubColumnTextModuleImageTypes {
  IMAGE = 'IMAGE',
  ICON = 'ICON',
}

export enum HubColumnTextModuleTextAlignmentTypes {
  LEFT = 'LEFT',
  CENTRE = 'CENTRE',
}

export type ColumnTextSubsectionType = {
  alt: string;
  body: string;
  title: string;
  link: HubModuleCTA;
  displayCtaLinkOnImage: boolean;
  displayCtaButton: boolean;
  image: UploadedImage;
  imageType: HubColumnTextModuleImageTypes;
  imageSize: 'FIT' | 'FILL';
  imagePosition: 'CENTRE' | 'LEFT' | 'RIGHT';
};

export enum ColumnTextModuleVisualStyles {
  Dark = 'DARK',
  Light = 'LIGHT',
  LightNoBorder = 'LIGHT_NO_BORDER',
  BrandDark = 'BRAND_DARK',
  BrandLight = 'BRAND_LIGHT',
}

export interface ColumnTextModuleType extends Omit<HubModule, 'visualStyle'> {
  '@type': HubModuleTypes.ColumnText;
  visualStyle: HubVisualStyles | ColumnTextModuleVisualStyles;
  link: HubModuleCTA;
  title: string;
  titleSize: TitleSizeType;
  subtitle: string;
  columnStyle: ColumnTextModuleColumnStyle;
  columnLayoutAlignment: 'LEFT' | 'RIGHT' | 'CENTRE';
  textAlignment: HubColumnTextModuleTextAlignmentTypes;
  subsections: ColumnTextSubsectionType[];
}

export interface ColumnTextModuleProps {
  module: ColumnTextModuleType;
}

export default function ColumnTextModule({
  module: {
    '@type': type,
    title,
    titleSize,
    columnStyle,
    subtitle,
    link,
    elementId,
    subsections,
    visualStyle,
    columnLayoutAlignment,
  },
  testId,
}: PropsWithTestId<ColumnTextModuleProps>) {
  const { theme } = useHubContext();
  const { type: siteType } = useRootSelector(selectSiteType);

  const itemGridClassName = classnames('col-xs-12', styles.itemBottomOffset, {
    'col-sm-6': columnStyle === ColumnTextModuleColumnStyle.TWO_COLUMNS,
    'col-sm-4': columnStyle === ColumnTextModuleColumnStyle.THREE_COLUMNS,
    'col-sm-3': columnStyle === ColumnTextModuleColumnStyle.FOUR_COLUMNS,
  });

  const visualStyleClassName = classnames({
    [styles.brandLight]:
      visualStyle === ColumnTextModuleVisualStyles.BrandLight,
    [styles.brandDark]: visualStyle === ColumnTextModuleVisualStyles.BrandDark,
    [styles.lightNoBorder]:
      visualStyle === ColumnTextModuleVisualStyles.LightNoBorder,
  });

  const linkVariant =
    visualStyle === ColumnTextModuleVisualStyles.BrandLight
      ? 'outlined'
      : 'contained';

  // TODO: move to configuration
  const rippleAnimation = siteType === 'BRAND_HUB' &&
    theme === 'LLOYDS_MARITIME_ACADEMY' &&
    visualStyle === ColumnTextModuleVisualStyles.BrandDark && (
      <div className={styles.turtles}>
        <div className={classnames(styles.turtle, styles.donatello)}>
          <Ripple count={14} delay={0} />
        </div>
        <div className={classnames(styles.turtle, styles.michelangelo)}>
          <Ripple count={14} delay={2} />
        </div>
        <div className={classnames(styles.turtle, styles.raphael)}>
          <Ripple count={11} delay={4} />
        </div>
        <div className={classnames(styles.turtle, styles.leonardo)}>
          <Ripple count={7} delay={6} />
        </div>
      </div>
    );

  function getImageVariantClassName(
    imageSize: ColumnTextSubsectionType['imageSize'],
    imagePosition: ColumnTextSubsectionType['imagePosition'],
  ) {
    return classnames(styles.imageHolder, {
      [styles.imageSizeFit]: imageSize === 'FIT',
      [styles.imageSizeFill]: imageSize === 'FILL',
      [styles.imagePositionCenter]: imagePosition === 'CENTRE',
      [styles.imagePositionLeft]: imagePosition === 'LEFT',
      [styles.imagePositionRight]: imagePosition === 'RIGHT',
    });
  }

  return (
    <HubModuleWrapper
      type={type}
      theme={visualStyle}
      elementId={elementId}
      testId={testId}
      overlay={rippleAnimation}
      className={visualStyleClassName}
    >
      <div className="container">
        <div className="row">
          <div className="col-xs-12">
            <ModuleHeader
              title={title}
              titleSize={titleSize}
              text={subtitle}
              lineAfterHeading
            />
          </div>
        </div>
        <div className={styles.subsectionsOffset}>
          <div
            className={classnames('row', {
              'center-sm': columnLayoutAlignment === 'CENTRE',
              'end-sm': columnLayoutAlignment === 'RIGHT',
            })}
          >
            {subsections.map((subsection, index) => {
              const {
                image,
                imageType,
                imageSize,
                imagePosition,
                title: subsectionTitle,
                link: subsectionLink,
                displayCtaLinkOnImage,
                displayCtaButton,
                body,
              } = subsection;

              if (
                columnStyle ===
                ColumnTextModuleColumnStyle.ONE_COLUMN_IMAGE_RIGHT
              ) {
                return (
                  <OneColumnImageRightColumnStyle
                    subsection={subsection}
                    key={index}
                  />
                );
              }

              const imageClassName = classnames({
                [styles.iconHolder]:
                  imageType === HubColumnTextModuleImageTypes.ICON,
                [styles.imageHolder]:
                  imageType === HubColumnTextModuleImageTypes.IMAGE,
                [getImageVariantClassName(imageSize, imagePosition)]:
                  imageType === HubColumnTextModuleImageTypes.IMAGE,
              });

              return (
                <div className={itemGridClassName} key={index}>
                  <div className={styles.itemGridWrapper}>
                    {image && (
                      <Image
                        path={image.path}
                        link={subsectionLink}
                        displayLink={displayCtaLinkOnImage}
                        className={imageClassName}
                        testId="informa-image-tag"
                      />
                    )}
                    <div className={styles.itemBody}>
                      {subsectionTitle && (
                        <Heading
                          testId={`hub-module-heading-${index}`}
                          className={styles.itemHeading}
                        >
                          {subsectionTitle}
                        </Heading>
                      )}
                      {body && (
                        <Paragraph
                          testId={`hub-module-paragraph-${index}`}
                          className={styles.itemParagraph}
                          text={body}
                        />
                      )}
                    </div>
                    {subsectionLink?.shown && displayCtaButton && (
                      <div className={styles.itemFooter}>
                        <CallToAction
                          type="link"
                          link={subsectionLink}
                          className={styles.itemLink}
                          data-testid={`column-text-module-subsection-link-${index}`}
                        />
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            {link?.type && (
              <CallToAction
                link={link}
                variant={linkVariant}
                align="center"
                className={styles.ctaButtonOffset}
                data-testid="column-text-module-link"
              />
            )}
          </div>
        </div>
      </div>
    </HubModuleWrapper>
  );
}

ColumnTextModule.defaultProps = {
  testId: 'hub-column-text-module',
};
